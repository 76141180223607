<script setup>
import Content from '@/views/Layouts/Content.vue'
import Mystery from '@/components/Mystery/Mystery.vue'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { reactive, ref, onMounted, watch } from 'vue'

import { DateTime } from 'luxon'



const store = useStore()
const router = useRouter()

let tmp = store.getters['mystery/GET_MODEL']
tmp.date = DateTime.now()
const mystery = reactive(ref(tmp))

const create = () => {
  console.log('create')
  store.dispatch('mystery/createItem', mystery.value).then(() => {
    router.push('/mysteries')
  })
}

const formMystery = ref(null)
const v$ = reactive(ref({}))
onMounted(() => {
  watch(() => formMystery.value.v$, (val) => {
    v$.value = val
  })
})

const upd = (val) =>( mystery.value = val )

</script>
<template>
  <div>
    <!-- <pre>{{ v$ }}</pre> -->
    <Content>
      <template #title>
        <div class="">
          <h3 class="">Новая мистерия</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Создание новой мистерии</p>
        </div>
      </template>
      <template #content>
        <!-- <Contact /> -->
        <div class="mx-auto md:w-1/2">
          <Mystery ref="formMystery" :value="mystery" @input="upd" />
          <div class="mt-5">
            <button class="btn btn-primary" :disabled="v$.$error" @click="create">Создать</button>
          </div>
        </div>

      </template>
    </Content>
  </div>
</template>